<template>
  <div class="body">
    <!-- logo 电话 -->
    <div class="logo">
      <div>
        <router-link to="/index">
          <div></div>
        </router-link>
      </div>
      <div>
        <div></div>
      </div>
      <div>
        <div>
          <div>积云教育</div>
          <div>互联网+行业职业技术培训</div>
        </div>
      </div>
      <div style>
        <div></div>
        <div>
          <div>报名热线：</div>
          <div></div>
          <div>400-800-2320</div>
        </div>
      </div>
    </div>
    <!-- 菜单 -->
    <div class="nav">
      <div class="nav_con">
        <div v-for="(item, index) in navList" :key="index" :class="navindex == index ? 'ass' : 'bss'">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["navindex"],
  data() {
    return {
      navList: [
        {
          name: "首页",
          path: "/index",
        },
        {
          name: "AI短视频大师班",
          path: "",
        },
        // {
        //   name: "前端开发",
        //   path: "/calculationPage",
        // },
        {
          name: "人工智能",
          path: "/intelligence",
        },
        {
          name: "鸿蒙人工智能",
          path: "",
        },
        // {
        //   name: "全栈开发",
        //   path: "/fullstack",
        // },
        {
          name: "新媒体影视",
          path: "",
        },
        {
          name: "积云教育",
          path: "/aboutus",
        },
      ],
    };
  },
  created() { },
  mounted() { },
  methods: {},
};
</script>
<style>
.body {
  box-sizing: border-box;
  width: 100%;
  height: 11.875rem;
  background: #fff;
  /* border: 1px solid red; */
}

.logo {
  width: 1200px;
  height: 6.25rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.logo>div {
  /* border: 1px solid red; */
  height: 100%;
}

.logo>div:nth-child(1) {
  width: 5rem;
}

.logo>div:nth-child(1) div {
  width: 4.375rem;
  height: 3.75rem;
  margin-top: 1.25rem;
  background: url(../../assets/images/sy/logo1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.logo>div:nth-child(2) {
  width: 0.6rem;
}

.logo>div:nth-child(2) div {
  width: 1px;
  height: 3.5rem;
  margin-top: 1.75rem;
  border-left: 2px solid #bfbfbf;
}

.logo>div:nth-child(3) {
  width: 53rem;
}

.logo>div:nth-child(3)>div {
  width: 20rem;
  height: 4.375rem;
  /* border: 1px solid red; */
  margin-top: 1.75rem;
  text-align: left;
  font-family: MicrosoftYaHei;
  font-size: 1.1875rem;
  font-weight: normal;
  font-stretch: normal;
  /* line-height: 20px; */
  letter-spacing: 0px;
  color: #9fa0a0;
}

.logo>div:nth-child(3)>div>div:nth-child(1) {
  /* color: red; */
  /* margin-top: 1.25rem; */
}

.logo>div:nth-child(3)>div>div:nth-child(2) {
  /* color: red; */
  margin-top: 0.25rem;
}

.logo>div:nth-child(4) {
  width: 40rem;
  display: flex;
}

.logo>div:nth-child(4)>div {
  /* width: 12.5rem; */
  height: 1.5625rem;
  margin: 2.5rem auto;
  float: right;
  /* background: red; */
  display: flex;
  align-items: center;
}

.logo>div:nth-child(4)>div>div:nth-child(1) {
  font-size: 1.1875rem;
}

.logo>div:nth-child(4)>div>div:nth-child(2) {
  width: 1.1875rem;
  height: 1.1875rem;
  background: url(../../assets/images/sy/phone.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin-top: 0.2rem; */
}

.logo>div:nth-child(4)>div>div:nth-child(3) {
  font-family: MicrosoftYaHei;
  font-size: 1.1875rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 0.5rem;
}

.nav {
  width: 100%;
  height: 5.625rem;
  background-color: #063651;
  display: flex;
  justify-content: space-between;
}

.nav>div {
  width: 100rem;
  height: 5.625rem;
  background-color: #063651;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.nav>div>div {
  width: 170px;
  height: 100%;
  font-family: MicrosoftYaHei;
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  line-height: 5.625rem;
}

.nav>div>div a {
  color: #ffffff;
  text-decoration: none;
}

.nav_con>div:nth-child(1) {
  color: red;
}

.nav_con a {
  display: inline-block;
}

.ass {
  background: #f19716;
}
</style>
